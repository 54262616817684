import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M1250 2720 c-258 -30 -489 -147 -676 -342 -118 -123 -200 -257 -252
-413 -74 -221 -81 -559 -16 -790 121 -426 486 -743 928 -804 99 -14 333 -14
432 0 478 66 867 434 954 904 18 98 24 339 11 454 -61 524 -465 929 -986 990
-101 12 -296 12 -395 1z m-105 -71 c-4 -5 -12 -8 -18 -6 -15 5 -92 -28 -212
-90 -187 -96 -343 -243 -454 -427 -22 -37 -41 -70 -41 -72 0 -2 -14 -33 -30
-69 -17 -36 -30 -73 -30 -83 0 -10 -5 -23 -10 -28 -6 -6 -13 -24 -16 -40 -8
-41 -16 -45 -10 -4 9 67 54 202 69 208 4 2 7 10 7 17 0 15 54 112 101 182 57
85 220 237 311 290 94 56 171 93 191 93 8 0 17 3 21 7 8 9 81 30 109 32 10 0
15 -4 12 -10z m665 0 c0 -5 4 -7 10 -4 5 3 27 -1 47 -10 21 -10 47 -20 58 -24
44 -16 123 -58 185 -97 192 -121 354 -325 429 -540 26 -72 27 -118 2 -54 -29
75 -87 187 -137 263 -72 110 -241 270 -358 340 -53 31 -101 57 -106 57 -5 0
-24 8 -42 18 -18 10 -54 25 -80 32 -49 15 -63 30 -28 30 11 0 20 -5 20 -11z
m-141 -19 c345 -70 623 -283 779 -596 184 -369 146 -815 -96 -1140 -221 -297
-535 -457 -897 -457 -189 0 -338 34 -499 115 -119 59 -192 112 -292 212 -141
140 -237 305 -289 497 -50 181 -44 434 13 614 105 328 373 604 692 715 181 62
405 78 589 40z m880 -1488 c-11 -34 -32 -85 -62 -152 -41 -89 -166 -251 -245
-317 -64 -54 -199 -146 -242 -165 -90 -41 -162 -66 -202 -71 -24 -3 -34 -4
-23 -1 11 3 24 10 30 15 5 5 16 9 25 9 19 0 194 87 238 118 16 12 33 22 37 22
12 0 126 100 183 160 94 99 238 335 258 425 5 21 8 25 11 12 3 -9 -1 -34 -8
-55z m-2179 8 c17 -45 57 -129 73 -155 6 -11 27 -44 46 -75 37 -61 124 -164
185 -220 87 -79 160 -128 291 -193 33 -16 68 -33 77 -38 10 -5 26 -9 37 -9 10
0 24 -6 30 -14 10 -12 6 -13 -26 -5 -180 41 -411 193 -538 354 -22 28 -43 52
-46 55 -29 25 -145 264 -155 322 -8 41 7 28 26 -22z"/>
<path d="M893 2260 c-23 -5 -55 -16 -71 -24 -41 -21 -100 -92 -112 -135 -6
-22 -10 -253 -10 -586 0 -610 -1 -592 68 -661 71 -71 47 -69 651 -72 626 -3
624 -3 698 74 24 25 47 60 53 83 7 27 10 227 8 608 l-3 568 -33 48 c-22 31
-51 57 -85 75 l-52 27 -535 2 c-294 0 -554 -3 -577 -7z m1139 -53 c26 -12 61
-39 77 -61 l31 -39 0 -351 c1 -193 1 -451 1 -573 1 -258 -3 -275 -85 -331
l-48 -32 -560 0 c-549 0 -560 0 -600 21 -44 23 -68 46 -96 94 -16 27 -17 77
-17 590 l0 560 30 47 c21 34 45 55 80 73 l50 25 545 0 c542 -1 545 -1 592 -23z"/>
<path d="M1729 2182 c-7 -3 -36 -15 -63 -27 l-48 -22 -72 19 c-175 48 -360
-14 -465 -156 -59 -78 -81 -149 -78 -248 1 -46 8 -92 14 -103 10 -16 11 -9 6
37 -12 122 32 245 121 334 64 63 116 91 208 110 79 17 135 12 215 -18 l52 -19
53 30 c163 90 222 20 153 -184 -78 -229 -345 -544 -540 -637 -77 -36 -117 -37
-144 -2 -40 51 -27 110 72 319 64 134 72 158 75 221 4 64 2 73 -21 100 -21 24
-32 29 -67 28 -82 -3 -150 -105 -158 -238 -6 -93 12 -100 20 -8 12 130 111
244 173 198 16 -12 20 -26 19 -73 0 -60 -2 -65 -85 -238 -80 -169 -97 -260
-59 -322 19 -31 19 -32 0 -38 -11 -3 -35 -3 -53 0 -22 4 -45 2 -63 -7 -24 -12
-33 -13 -54 -2 -37 20 -74 17 -96 -8 -16 -20 -19 -38 -19 -119 1 -65 5 -102
14 -113 15 -18 73 -21 103 -5 14 8 23 8 31 0 14 -14 62 -14 88 0 13 7 24 7 32
1 16 -13 273 -15 297 -2 12 6 21 7 25 1 3 -6 77 -10 173 -9 92 0 172 -1 177
-2 6 -1 26 -2 45 -1 96 2 193 0 201 -4 15 -10 77 23 94 49 12 18 15 35 10 54
-4 15 -6 53 -5 84 1 51 -2 59 -24 73 -14 9 -30 14 -35 10 -14 -8 -82 5 -76 15
3 5 -13 -1 -35 -11 -23 -11 -49 -34 -59 -51 l-19 -30 -18 28 c-28 44 -45 53
-96 54 -58 0 -82 -26 -92 -100 -4 -31 -12 -50 -20 -50 -21 0 -25 11 -13 37 17
37 -15 90 -62 103 -47 13 -104 13 -133 0 -17 -8 -38 -7 -77 3 l-54 14 61 41
c69 47 70 48 86 26 20 -26 94 -21 162 11 100 49 93 48 100 7 7 -40 31 -75 46
-66 5 3 63 5 129 5 130 -1 159 7 159 43 0 51 -43 76 -127 75 -7 0 -30 -1 -50
-1 -30 -1 -87 14 -112 29 -2 1 12 26 31 55 57 89 78 149 78 225 0 45 -17 93
-33 93 -12 0 -6 27 26 115 46 129 43 213 -10 255 -25 19 -73 25 -114 12z m91
-532 c-35 -160 -175 -293 -330 -316 l-45 -6 48 32 c111 75 268 269 313 386
l19 49 3 -45 c2 -25 -2 -70 -8 -100z m-22 -272 c31 -31 3 -78 -46 -78 -16 0
-21 8 -24 45 -3 44 -3 45 27 45 17 0 36 -5 43 -12z m133 -5 c5 -48 2 -73 -7
-73 -7 0 -14 15 -17 33 -5 34 -1 47 14 47 5 0 9 -3 10 -7z m-58 -10 c2 -5 7
-20 11 -35 6 -24 4 -26 -26 -25 -36 2 -50 26 -19 34 11 3 18 9 15 14 -3 5 -10
7 -15 4 -5 -4 -9 -1 -9 4 0 12 37 15 43 4z m130 0 c2 -5 7 -21 11 -36 6 -27 5
-28 -26 -25 -38 4 -55 38 -18 38 12 0 18 4 15 10 -4 6 -11 8 -16 5 -5 -4 -9
-1 -9 4 0 12 37 15 43 4z m-1035 -204 l37 -54 3 35 c2 19 -1 36 -7 38 -6 2
-11 12 -11 23 0 16 7 19 40 19 33 0 40 -3 40 -20 0 -11 -4 -20 -10 -20 -6 0
-10 -35 -10 -85 l0 -85 -27 0 c-23 0 -35 10 -63 55 -19 30 -38 55 -42 55 -5 0
-8 -18 -8 -40 0 -29 4 -40 15 -40 8 0 15 -7 15 -15 0 -11 -12 -15 -45 -15 -33
0 -45 4 -45 14 0 8 5 18 10 21 6 3 10 35 10 71 0 36 -4 64 -10 64 -5 0 -10 9
-10 19 0 16 7 19 40 17 37 -2 44 -7 78 -57z m222 40 c0 -10 -4 -19 -10 -19 -6
0 -10 -28 -10 -64 0 -36 5 -68 10 -71 6 -3 10 -13 10 -21 0 -10 -12 -14 -45
-14 -33 0 -45 4 -45 14 0 8 5 18 10 21 6 3 10 35 10 71 0 36 -4 64 -10 64 -5
0 -10 9 -10 19 0 16 7 19 45 19 38 0 45 -3 45 -19z m186 1 c17 -15 18 -52 2
-69 -11 -10 -10 -14 5 -22 22 -13 22 -57 0 -81 -22 -24 -173 -27 -173 -3 0 8
5 15 10 15 15 0 13 137 -2 142 -6 2 -9 11 -6 20 5 13 20 15 78 13 39 -2 78 -9
86 -15z m204 -5 c26 -24 25 -50 -2 -70 -21 -15 -22 -17 -5 -26 9 -5 17 -19 17
-29 0 -23 10 -26 28 -8 9 9 12 8 12 -8 0 -28 -19 -44 -51 -44 -31 0 -39 9 -48
54 -9 39 -41 49 -41 12 0 -14 5 -28 10 -31 6 -3 10 -13 10 -21 0 -10 -12 -14
-45 -14 -33 0 -45 4 -45 14 0 8 5 18 10 21 6 3 10 35 10 71 0 36 -4 64 -10 64
-5 0 -10 9 -10 19 0 17 7 19 70 17 51 -2 76 -8 90 -21z m247 -61 c14 -43 31
-84 39 -90 29 -23 14 -34 -46 -34 -47 0 -60 3 -60 15 0 8 7 15 15 15 8 0 15 7
15 15 0 11 -11 15 -40 15 -29 0 -40 -4 -40 -15 0 -8 5 -15 10 -15 6 0 10 -7
10 -15 0 -11 -12 -15 -45 -15 -32 0 -45 4 -45 14 0 8 6 16 13 19 17 7 47 115
40 149 -5 26 -5 26 52 24 l58 -3 24 -79z m191 70 c20 -14 22 -14 22 0 0 9 9
16 20 16 17 0 20 -7 20 -39 0 -36 -2 -39 -22 -33 -13 3 -27 11 -31 19 -4 7
-19 13 -32 13 -44 0 -28 -29 29 -54 62 -27 76 -54 52 -91 -13 -20 -25 -25 -59
-25 -24 0 -49 6 -56 13 -11 10 -14 10 -18 0 -3 -7 -14 -13 -26 -13 -18 0 -22
6 -23 35 0 30 3 35 22 35 12 0 27 -7 34 -16 15 -17 61 -15 67 3 3 7 -13 17
-37 23 -23 6 -50 20 -61 31 -56 56 31 131 99 83z"/>
<path d="M1760 1350 c0 -30 13 -40 25 -20 8 13 -4 50 -16 50 -5 0 -9 -13 -9
-30z"/>
<path d="M1280 1155 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M1282 1073 c2 -22 8 -28 28 -28 20 0 25 5 25 25 0 20 -6 26 -28 28
-26 3 -28 1 -25 -25z"/>
<path d="M1490 1156 c0 -14 4 -27 9 -30 18 -11 41 6 41 30 0 19 -5 24 -25 24
-20 0 -25 -5 -25 -24z"/>
<path d="M1736 1148 c-10 -38 -7 -48 14 -48 11 0 20 6 20 13 0 23 -12 57 -20
57 -4 0 -10 -10 -14 -22z"/>
<path d="M1684 2099 l-42 -20 61 -60 c34 -32 64 -56 68 -51 11 13 29 74 29
101 0 54 -44 65 -116 30z m76 -44 c0 -30 -11 -32 -34 -6 -13 15 -14 20 -4 24
27 11 38 6 38 -18z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
